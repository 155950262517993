import { getPrismicText } from '@utils'

export default (prismic) => {
  const { prismicCompaniesPage = {} } = { ...prismic }
  const { data = {} } = { ...prismicCompaniesPage }
  const { body = [] } = { ...data }
  const [
    navbar = {},
    hero = {},
    perks = {},
    works = {},
    satisfactionSection = {},
    brandsSection = {},
    testimonialsCarousel = {},
    dataSection = {},
    bodyCard = {},
    ready = {},
    footer = {},
  ] = [...body]
  const { primary: navbarPrimary = {} } = { ...navbar }
  const { primary: heroPrimary = {} } = { ...hero }
  const { primary: perksSectionPrimary = {}, items: perksSectionItems = [] } = {
    ...perks,
  }
  const { primary: worksSectionPrimary = {}, items: worksSectionItems = [] } = {
    ...works,
  }
  const {
    primary: satisfactionSectionPrimary = {},
    items: satisfactionSectionItems = [],
  } = { ...satisfactionSection }
  const {
    primary: brandsSectionPrimary = {},
    items: brandsSectionItems = [],
  } = { ...brandsSection }
  const { items: carouselTestimonialItems = [] } = { ...testimonialsCarousel }
  const { primary: dataSectionPrimary = {} } = { ...dataSection }
  const {
    primary: bodyCardSectionPrimary = {},
    items: bodyCardSectionItems = [],
  } = { ...bodyCard }
  const { primary: readySectionPrimary = {} } = { ...ready }
  const { primary: footerSectionPrimary = {} } = { ...footer }

  const {
    navbar_section_logo,
    navbar_section_logo_mobile,
    navbar_section_cta_label_home,
    navbar_section_cta_link_home,
    navbar_section_cta_label_providers,
    navbar_section_cta_link_providers,
    navbar_section_cta_label_companies,
    navbar_section_cta_link_companies,
    navbar_section_cta_label_demo,
    navbar_section_cta_link_demo,
    navbar_section_cta_label_login,
    navbar_section_cta_link_login,
    navbar_section_cta_label_switcher,
    navbar_section_cta_link_switcher,
  } = { ...navbarPrimary }
  const {
    hero_cta_label,
    hero_cta_link,
    hero_image,
    hero_paragraph,
    hero_title,
  } = { ...heroPrimary }
  const { perks_section_title } = { ...perksSectionPrimary }
  const { works_section_title, works_section_paragraph } = {
    ...worksSectionPrimary,
  }
  const { satisfaction_section_title } = { ...satisfactionSectionPrimary }
  const { brands_section_title } = { ...brandsSectionPrimary }
  const {
    data_section_title,
    data_section_paragraph,
    data_section_cta_label,
    data_section_cta_link,
    data_section_image,
  } = { ...dataSectionPrimary }
  const { body_card_section_title, body_card_section_paragraph } = {
    ...bodyCardSectionPrimary,
  }
  const {
    ready_section_title,
    ready_section_paragraph,
    ready_section_cta_label,
    ready_section_cta_link,
  } = { ...readySectionPrimary }
  const {
    footer_section_logo,
    footer_section_cta_label_about,
    footer_section_cta_label_with_us,
    footer_section_cta_label_press,
    footer_section_cta_link_press,
    footer_section_cta_label_contact,
    footer_section_logo_facebook,
    footer_section_cta_link_facebook,
    footer_section_logo_instagram,
    footer_section_cta_link_instagram,
    footer_section_logo_linkedin,
    footer_section_cta_link_linkedin,
    footer_section_copyright,
    footer_section_cta_label_privacy,
    footer_section_cta_label_terms,
  } = { ...footerSectionPrimary }

  return {
    navbar: {
      logo: navbar_section_logo.url,
      logoMobile: navbar_section_logo_mobile.url,
      ctaLabelHome: getPrismicText(navbar_section_cta_label_home),
      ctaLinkHome: getPrismicText(navbar_section_cta_link_home),
      ctaLabelProviders: getPrismicText(navbar_section_cta_label_providers),
      ctaLinkProviders: getPrismicText(navbar_section_cta_link_providers),
      ctaLabelCompanies: getPrismicText(navbar_section_cta_label_companies),
      ctaLinkCompanies: getPrismicText(navbar_section_cta_link_companies),
      ctaLabelDemo: getPrismicText(navbar_section_cta_label_demo),
      ctaLinkDemo: navbar_section_cta_link_demo.url,
      ctaLabelLogin: getPrismicText(navbar_section_cta_label_login),
      ctaLinkLogin: navbar_section_cta_link_login.url,
      ctaLabelSwitcher: getPrismicText(navbar_section_cta_label_switcher),
      ctaLinkSwitcher: getPrismicText(navbar_section_cta_link_switcher),
    },
    hero: {
      title: getPrismicText(hero_title),
      description: getPrismicText(hero_paragraph),
      background: hero_image.url,
      ctaLink: hero_cta_link.url,
      ctaLabel: getPrismicText(hero_cta_label),
    },
    perks: {
      title: getPrismicText(perks_section_title),
    },
    perksSectionItems,
    works: {
      title: getPrismicText(works_section_title),
      description: getPrismicText(works_section_paragraph),
    },
    worksSectionItems,
    satisfaction: {
      title: getPrismicText(satisfaction_section_title),
    },
    satisfactionSectionItems,
    brands: {
      title: getPrismicText(brands_section_title),
    },
    brandsSectionItems,
    carouselTestimonialItems,
    dataSection: {
      title: getPrismicText(data_section_title),
      description: getPrismicText(data_section_paragraph),
      ctaLabel: getPrismicText(data_section_cta_label),
      ctaLink: data_section_cta_link.url,
      image: data_section_image.url,
    },
    bodyCard: {
      title: getPrismicText(body_card_section_title),
      description: getPrismicText(body_card_section_paragraph),
    },
    bodyCardSectionItems,
    ready: {
      title: getPrismicText(ready_section_title),
      description: getPrismicText(ready_section_paragraph),
      ctaLink: ready_section_cta_link.url,
      ctaLabel: getPrismicText(ready_section_cta_label),
    },
    footer: {
      logo: footer_section_logo.url,
      about: getPrismicText(footer_section_cta_label_about),
      withUs: getPrismicText(footer_section_cta_label_with_us),
      ctaLabelPress: getPrismicText(footer_section_cta_label_press),
      ctaLinkPress: getPrismicText(footer_section_cta_link_press),
      contact: getPrismicText(footer_section_cta_label_contact),
      logoFacebook: footer_section_logo_facebook.url,
      ctaLinkFaceebook: footer_section_cta_link_facebook.url,
      logoInstagram: footer_section_logo_instagram.url,
      ctaLinkInstagram: footer_section_cta_link_instagram.url,
      logoLinkedin: footer_section_logo_linkedin.url,
      ctaLinkLinkedin: footer_section_cta_link_linkedin.url,
      copyright: getPrismicText(footer_section_copyright),
      privacy: getPrismicText(footer_section_cta_label_privacy),
      terms: getPrismicText(footer_section_cta_label_terms),
    },
  }
}
