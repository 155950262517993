import { getPrismicText } from '@utils'

export default (prismic) => {
  const { prismicProvidersPage = {} } = { ...prismic }
  const { data = {} } = { ...prismicProvidersPage }
  const { body = [] } = { ...data }
  const [ navbar = {}, hero = {}, perks = {}, works = {}, dataSection = {}, card = {}, ready = {}, footer = {} ] = [...body]
  const { primary: navbarPrimary = {} } = { ...navbar }
  const { primary: heroPrimary = {} } = { ...hero }
  const { primary: perksSectionPrimary = {}, items: perksSectionItems = [] } = { ...perks }
  const { primary: worksSectionPrimary = {}, items: worksSectionItems = [] } = { ...works }
  const { primary: dataSectionPrimary = {} } = { ...dataSection }
  const { primary: cardSectionPrimary = {}, items: cardSectionItems = [] } = { ...card }
  const { primary: readySectionPrimary = {} } = { ...ready }
  const { primary: footerSectionPrimary = {} } = { ...footer }

  const {
    navbar_section_logo,
    navbar_section_logo_mobile,
    navbar_section_cta_label_home,
    navbar_section_cta_link_home,
    navbar_section_cta_label_providers,
    navbar_section_cta_link_providers,
    navbar_section_cta_label_companies,
    navbar_section_cta_link_companies,
    navbar_section_cta_label_demo,
    navbar_section_cta_link_demo,
    navbar_section_cta_label_login,
    navbar_section_cta_link_login,
    navbar_section_cta_label_switcher,
    navbar_section_cta_link_switcher,
  } = { ...navbarPrimary }
  const {
    hero_cta_label,
    hero_cta_link,
    hero_image,
    hero_paragraph,
    hero_title,
  } = { ...heroPrimary }
  const {
    perks_section_title,
  } = { ...perksSectionPrimary }
  const {
    works_section_title,
    works_section_paragraph,
  } = { ...worksSectionPrimary }
  const {
    data_section_title,
    data_section_paragraph,
    data_section_cta_label,
    data_section_cta_link,
    data_section_image,
  } = { ...dataSectionPrimary }
  const {
    card_section_title1,
    card_section_paragraph,
  } = { ...cardSectionPrimary }
  const {
    ready_section_title,
    ready_section_paragraph,
    ready_section_cta_label,
    ready_section_cta_link,
  } = { ...readySectionPrimary }
  const {
    footer_section_logo,
    footer_section_cta_label_about,
    footer_section_cta_label_with_us,
    footer_section_cta_label_press,
    footer_section_cta_link_press,
    footer_section_cta_label_contact,
    footer_section_logo_facebook,
    footer_section_cta_link_facebook,
    footer_section_logo_instagram,
    footer_section_cta_link_instagram,
    footer_section_logo_linkedin,
    footer_section_cta_link_linkedin,
    footer_section_copyright,
    footer_section_cta_label_privacy,
    footer_section_cta_label_terms,
  } = { ...footerSectionPrimary }

  return {
    navbar: {
      logo: navbar_section_logo.url,
      logoMobile: navbar_section_logo_mobile.url,
      ctaLabelHome: getPrismicText(navbar_section_cta_label_home),
      ctaLinkHome: getPrismicText(navbar_section_cta_link_home),
      ctaLabelProviders: getPrismicText(navbar_section_cta_label_providers),
      ctaLinkProviders: getPrismicText(navbar_section_cta_link_providers),
      ctaLabelCompanies: getPrismicText(navbar_section_cta_label_companies),
      ctaLinkCompanies: getPrismicText(navbar_section_cta_link_companies),
      ctaLabelDemo: getPrismicText(navbar_section_cta_label_demo),
      ctaLinkDemo: navbar_section_cta_link_demo.url,
      ctaLabelLogin: getPrismicText(navbar_section_cta_label_login),
      ctaLinkLogin: navbar_section_cta_link_login.url,
      ctaLabelSwitcher: getPrismicText(navbar_section_cta_label_switcher),
      ctaLinkSwitcher: getPrismicText(navbar_section_cta_link_switcher),
    },
    hero: {
      title: getPrismicText(hero_title),
      description: getPrismicText(hero_paragraph),
      background: hero_image.url,
      ctaLink: hero_cta_link.url,
      ctaLabel: getPrismicText(hero_cta_label),
    },
    perks: {
      title: getPrismicText(perks_section_title),
    },
    perksSectionItems,
    works: {
      title: getPrismicText(works_section_title),
      description: getPrismicText(works_section_paragraph),
    },
    worksSectionItems,
    dataSection: {
      title: getPrismicText(data_section_title),
      description: getPrismicText(data_section_paragraph),
      ctaLabel: getPrismicText(data_section_cta_label),
      ctaLink: data_section_cta_link.url,
      image: data_section_image.url,   
    },
    card: {
      title: getPrismicText(card_section_title1),
      description: getPrismicText(card_section_paragraph),
    },
    cardSectionItems,
    ready: {
      title: getPrismicText(ready_section_title),
      description: getPrismicText(ready_section_paragraph),
      ctaLink: ready_section_cta_link.url,
      ctaLabel: getPrismicText(ready_section_cta_label),
    },
    footer: {
      logo: footer_section_logo.url,
      about: getPrismicText(footer_section_cta_label_about),
      withUs: getPrismicText(footer_section_cta_label_with_us),
      ctaLabelPress: getPrismicText(footer_section_cta_label_press),
      ctaLinkPress: getPrismicText(footer_section_cta_link_press),
      contact: getPrismicText(footer_section_cta_label_contact),
      logoFacebook: footer_section_logo_facebook.url,
      ctaLinkFaceebook: footer_section_cta_link_facebook.url,
      logoInstagram: footer_section_logo_instagram.url,
      ctaLinkInstagram: footer_section_cta_link_instagram.url,
      logoLinkedin: footer_section_logo_linkedin.url,
      ctaLinkLinkedin: footer_section_cta_link_linkedin.url,
      copyright: getPrismicText(footer_section_copyright),
      privacy: getPrismicText(footer_section_cta_label_privacy),
      terms: getPrismicText(footer_section_cta_label_terms),
    },
  }
}
